import type { UserModel } from "@/services/api/user/models/user.model";
import { useContext } from "react";
import { UserContext } from "../user.context";

export type UseUser = {
  readonly isLoggedIn: boolean;
  readonly urls: {
    readonly createLoginUrl: (redirectTo: string) => string;
    readonly createLogoutUrl: (url: string) => string;
    readonly loginAndReturnUrl: string;
    readonly logoutAndReturnUrl: string;
    readonly manageAccount: string;
  };
  readonly model: UserModel;
};

export function useUser(): UseUser {
  const user = useContext(UserContext);

  if (user === undefined) {
    throw new Error(
      "An UserProvider must be present in the component hierarchy.",
    );
  }

  return user;
}
