"use client";

import { useTrackingData } from "@/contexts/tracking/tracking.context";
import { useUser } from "@/contexts/user/hooks/use-user.hook";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { UserModel } from "@/services/api/user/models/user.model";
import { useEffect, useRef, useState } from "react";
import type { TrackingDataModel } from "../models/tracking-data-model.types";

export function useExponeaPageView(referer: null | string) {
  const { trackingData } = useTrackingData();
  const { model: user } = useUser();
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  const isTracked = useRef<boolean>(false);
  const consent = useCookiebotConsent();

  // biome-ignore lint/correctness/useExhaustiveDependencies: This hook specifies more dependencies than necessary
  useEffect(() => {
    if (
      isTracked.current ||
      typeof exponea === "undefined" ||
      trackingData === null ||
      !consent.submitted
    ) {
      return;
    }

    const ssoId = user.user?.ssoId ?? null;

    if (!exponea.isInitialized) {
      exponea.start({
        customer: ssoId !== "anon" ? { sso_id: ssoId ?? undefined } : {},
      });
      setIsScriptLoaded(true);
    }
    if (exponea.isInitialized) {
      isTracked.current = true;
      if (
        typeof exponea.configuration.customer.sso_id !== "undefined" &&
        exponea.configuration.customer.sso_id !== ssoId
      ) {
        exponea.anonymize();
      }
      exponea.identify(ssoId !== "anon" ? { sso_id: ssoId ?? undefined } : {});

      exponea.track("page_visit", getContext(referer, trackingData, user));
    }
  }, [referer, trackingData, isScriptLoaded, consent]);
}

function getContext(
  ref: null | string,
  trackingData: TrackingDataModel,
  user: UserModel,
) {
  return {
    access_agreement_account_numbers: user.accessAgreements
      .map((agreement) => agreement.accountNumber)
      .join(","),
    access_agreement_ids: user.accessAgreements
      .map((agreement) => agreement.accessAgreementId)
      .join(","),
    article_locked: trackingData.pageRestricted === "yes",
    authorization_method:
      user.access.class !== "none" ? user.access.class : "NOTSET",
    content_id: trackingData.contentId,
    have_access: user.hasAccess,
    logged_in: user.loggedIn,
    page_restricted_type: trackingData.pageRestrictedType,
    referrer: ref ?? "",
    section_name: trackingData.sectionName,
    site: trackingData.site,
    ssoId:
      user.user !== null &&
      user.user.ssoId !== null &&
      user.user.ssoId !== "anon"
        ? user.user.ssoId
        : undefined,
    subscription_account_numbers: user.subscriptions
      .map((subscription) => subscription.accountNumber)
      .join(","),
  };
}
